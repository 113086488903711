import {stateType} from "@/views/ShopAdmin/BusinessSuc/Model/indexType";


export const stateList:stateType[] = [
    {
        value:"序号",
        num:0,
        state:""
    },{
        value:"区域",
        num:0,
        state:""
    },{
        value:"待接单",
        num:0,
        state:"totalDelivery"
    },{
        value:"待取货",
        num:0,
        state:"totalPickUp"
    },{
        value:"待送达",
        num:0,
        state:"totalArrive"
    },{
        value:"已完成",
        num:0,
        state:"totalComplete"
    },
]


export const distanceList:stateType[] = [
    {
        value:"序号",
        num:0,
        state:""
    },{
        value:"区域",
        num:0,
        state:""
    },{
        value:"0-15/m",
        num:0,
        state:"totalTime15"
    },{
        value:"15-30/m",
        num:0,
        state:"totalTime30"
    },{
        value:"30-45/m",
        num:0,
        state:"totalTime45"
    },{
        value:"45-60/m",
        num:0,
        state:"totalMaxTime"
    },
]

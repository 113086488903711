import {tenantSuc} from "@/Api/ShopAdmin";
import Storage from "@/util/Storage"
import { stateList,distanceList } from "@/views/ShopAdmin/BusinessSuc/Model/index";

export function getCommercial() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( tenantSuc({ userId,token }).then(res=>{
        let data:any = { ...res.data,stateList:[],distanceList:[],orderStateList:[],orderAreaList:[] }
        stateList.length && stateList.forEach((item,index)=>{
            if ( item.state ){
                item.num = res.data[item.state]
            }else{
                item.num = 0
            }
        });
        distanceList.length && distanceList.forEach((item,index)=>{
            if ( item.state ){
                item.num = res.data[item.state]
            }else{
                item.num = 0
            }
        })
        let stateArr:any[] = [],areaArr:any[] = []
        res.data.areaOrderList.length && res.data.areaOrderList.forEach((item:any,index:number)=>{
            let orderState:any[]=[
                index + 1,
                item.area || '',
                item.delivery || 0,
                item.pickUp || 0,
                item.arrive || 0,
                item.complete || 0
            ],orderArea:any[]=[
                index + 1,
                item.area || '',
                item.time15 || 0,
                item.time30 || 0,
                item.time45 || 0,
                item.maxTime || 0
            ];
            stateArr.push(orderState)
            areaArr.push(orderArea)
        })
        data.stateList = stateList
        data.distanceList = distanceList
        data.orderStateList = stateArr
        data.orderAreaList = areaArr
        return data
    }) )
}
